import app from "./config/configApp";
import router from "./routes/protectedRoute";
import store from "@/vuex/store";
import { createPinia } from "pinia";
import "./static/css/style.css";
// Vue 3rd party plugins
import "@/core/plugins/ant-design";
import "@/core/plugins/fonts";
import "@/core/plugins/masonry";
import "@/core/plugins/unicons";
import "@/core/components/custom";
import "@/core/components/style";
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
//import { isShallow } from "vue";

const pinia = createPinia();

const allowedDomains = [
  /^https:\/\/.*\.entecglobal.com(\/.*)?$/,
  "http://localhost:8080",
  "http://localhost:8081",
  /^https:\/\/.*\.ariba\.com(\/.*)?$/, // Regular expression for dynamic subdomains with optional paths and parameters
];

// Get the current URL
const currentUrl = window.location.href;

// Check if the current domain is in the allowed domains list
let isAllowed = allowedDomains.some((domain) => {
  if (domain instanceof RegExp) {
    return domain.test(currentUrl);
  }
  return currentUrl.startsWith(domain);
});

// If the current domain is not in the allowed list, check if it's within an iframe
if (!isAllowed && window.parent) {
  const parentUrl = document.referrer;
  const isParentAllowed = allowedDomains.some((domain) => {
    if (domain instanceof RegExp) {
      return domain.test(parentUrl);
    }
    return parentUrl.startsWith(domain);
  });

  // If the parent domain is in the allowed list, consider the current domain as allowed
  if (isParentAllowed) {
    isAllowed = true;
  }
}

// If the current domain is not in the allowed list, redirect or take appropriate action
if (!isAllowed) {
  window.location.href = "https://need2.com"; // Redirect to a fallback page
  // Or you can choose to display an error message, render alternative content, etc.
}
  

app.config.productionTip = false;
app.use(store);
app.use(pinia);
app.use(router);
app.use(VueViewer);
app.mount("#app");
