import staticData from '@/config/config';


const state = () => ({
  main: staticData.mainTemplate,


});


export default {
  state,
};
