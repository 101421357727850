const routes = [
  {
    name: "ecommerce",
    path: "",
    component: () =>
      import(
        /* webpackChunkName: "ecommerce" */ "../view/apps/ecommerce/Index.vue"
      ),
    children: [
      {
        path: "",
        name: "ecommerce-product",
        component: () =>
          import(
            /* webpackChunkName: "product" */ "../view/apps/ecommerce/product/Products.vue"
          ),
        children: [
          {
            name: "pro-grid",
            path: "",
            components: {
              grid: () =>
                import(
                  /* webpackChunkName: "grid" */ "../view/apps/ecommerce/product/overview/Grid.vue"
                ),
            },
          },
          {
            name: "pro-list",
            path: "list",
            components: {
              grid: () =>
                import(
                  /* webpackChunkName: "grid" */ "../view/apps/ecommerce/product/overview/List.vue"
                ),
            },
          },
        ],
      },
      {
        name: "detail",
        path: "details/:id",
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../view/apps/ecommerce/product/ProductDetails.vue"
          ),
      },

      {
        name: "cart",
        path: "/cart",
        component: () =>
          import(
            /* webpackChunkName: "cart" */ "../view/apps/ecommerce/Cart.vue"
          ),
        children: [
          {
            name: "exact",
            path: "",
            components: {
              default: () =>
                import(
                  /* webpackChunkName: "cart" */ "../view/apps/ecommerce/Cart.vue"
                ),
              child: () =>
                import(
                  /* webpackChunkName: "cartTable" */ "../view/apps/ecommerce/overview/CartTable.vue"
                ),
            },
          },
        ],
      },

      {
        name: "request quote",
        path: "/quotes",
        component: () =>
          import(
            /* webpackChunkName: "cart" */ "../view/apps/requestedquotes/Quotes.vue"
          ),
      },
      {
        name: "request quote item",
        path: "quotes/:id",
        component: () =>
          import(
            /* webpackChunkName: "detail" */ "../view/apps/requestedquotes/QuoteDetails.vue"
          ),
      },
    ],
  },
];

export default routes;
